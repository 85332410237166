@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Grandis Extended";
    src: url("../fonts/GrandisExtended/GrandisExtended-Bold.ttf");
  }
  @font-face {
    font-family: "Grandis Extended";
    src: url("../fonts/GrandisExtended/GrandisExtended-Regular.ttf");
  }
  @font-face {
    font-family: "Grandis Extended Bold";
    src: url("../fonts/GrandisExtended/GrandisExtended-Bold.ttf");
  }
  @font-face {
    font-family: "Grandis Extended Regular";
    src: url("../fonts/GrandisExtended/GrandisExtended-Regular.ttf");
  }
}

.swapping > div {
  position: absolute;
} 

.slick-slider > button {
  display: none !important;
}

html.dark {
  background-color: black;
}

.container {
  max-width: 2560px;
}

.btn-sort {
  background-image: url(../img/icon-filter-light.png);
}

.dark .btn-sort {
  background-image: url(../img/icon-filter-dark.png);
}

.btn-sort-close {
  background-image: url(../img/icon-close-light.png);
}

.dark .btn-sort-close {
  background-image: url(../img/icon-close-dark.png);
}

.dark .btn-radio {
  background-image: url(../img/icon-radio-dark-off.png);
}

.btn-radio {
  background-image: url(../img/icon-radio-light-off.png);
}

.btn-refresh {
  background-image: url(../img/icon-refresh.png);
}

.dark .btn-refresh {
  background-image: url(../img/icon-refresh-dark.png);
}

.btn-repeat-selected:hover svg {
  fill: #ffffff50;
}

p, img {
  user-select: none;
}

.pluse {
  animation: pulse 2s cubic-bezier(0.4, 3, 0.6, 4) infinite;
}

.pluse-two {
  animation: pulse 10s cubic-bezier(0.4, 1, 0.5, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}
