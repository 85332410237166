.landing-card-tilt {
  transform: perspective(400px) rotateY(20deg);
}

.init-card-tilt {
  transform: perspective(400px) rotateY(20deg);
  animation: lighteffect .1s ease-in;
}

@keyframes lighteffect {
  0% {
    opacity: .5;
    transform: perspective(400px) scale(.99) rotateY(20deg);
  }

  100% {
    opacity: 1;
    transform: perspective(400px) scale(1) rotateY(20deg);
  }
}

.carousel {
  padding: 0;
  margin: 0;
  transform: translate3d(-50%, -40%, 0);
}

.item {
  width: 150px;
  height: 170px;
  float: left;
  list-style: none;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.5s ease;
}

.selector {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.item:last-child {
  margin-right: 0;
}

[data-position="1"] {
  transform: scale(1.7) translate3d(-155px, 0px, 0px);
  z-index: 1;
}

[data-position="2"] {
  transform: scale(2) translate3d(-85px, 0px, 0px);
  z-index: 2;
}

[data-position="3"] {
  transform: scale(2.3) translate3d(-35px, 0px, 0px);
  z-index: 3;
}

[data-position="4"] {
  transform: scale(2.5) translate3d(15px, 0px, 0px);
  z-index: 4;
}

@keyframes crossfade {
  0% {
    opacity: 1;
  }

  5% {
    opacity: 0.7;
  }

  10% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.first-card-animation {
  animation: firstCardAnimation 1s ease-in-out;
}

@keyframes firstCardAnimation {
  0% {
    transform: scale(0.8, 0.8);
    transform: perspective(400px) rotateY(25deg);
  }

  50% {
    transform: scale(0.6, 0.6);
    transform: perspective(600px) rotateY(65deg);
  }

  100% {
    transform: scale(0.1, 0.1);
    transform: perspective(800px) rotateY(75deg);
  }
}

.first-card-animation-div {
  animation: firstCardAnimationDiv 1.2s ease-in-out;
}

@keyframes firstCardAnimationDiv {
  0% {}

  50% {
    transform: scale(2.2, 2.2);
  }

  100% {
    transform: scale(1.1, 1.1);
    z-index: 1;
  }
}

.second-card-animation {
  animation: secondCardAnimation 1s ease-in-out;
}

@keyframes secondCardAnimation {
  0% {
    transform: perspective(400px) rotateY(20deg);
  }

  50% {
    transform: perspective(600px) rotateY(10deg);
  }

  100% {
    transform: perspective(400px) rotateY(20deg);
  }
}

.second-card-animation-div {
  animation: secondCardAnimationDiv 0.9s ease-in-out;
}

@keyframes secondCardAnimationDiv {
  0% {
    transform: scale(2.3) translate3d(-35px, 0px, 0px);
  }

  50% {
    transform: scale(2.6) translate3d(-75px, 0px, 0px);
  }

  100% {
    transform: scale(2.5) translate3d(15px, 0px, 0px);
  }
}

[type=radio] {
  display: none;
}

#slider {
  height: 345px;
  width: 235px;
  margin: 0 auto;
  position: relative;
}

#slider label {
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  cursor: pointer;
  transition: transform .7s ease;
}

#s1:checked~#slide1,
#s2:checked~#slide2,
#s3:checked~#slide3,
#s4:checked~#slide4 {
  transform: scale(1.3) translate3d(15px, 0px, 0px);
  z-index: 4;
}

#s1:checked~#slide2,
#s2:checked~#slide3,
#s3:checked~#slide4,
#s4:checked~#slide1 {
  transform: scale(1.2) translate3d(-55px, 0px, 0px);
  z-index: 3;
}

#s1:checked~#slide3,
#s2:checked~#slide4,
#s3:checked~#slide1,
#s4:checked~#slide2 {
  transform: scale(1.1) translate3d(-145px, 0px, 0px);
  z-index: 2;
}

#s1:checked~#slide4,
#s2:checked~#slide1,
#s3:checked~#slide2,
#s4:checked~#slide3 {
  transform: scale(1) translate3d(-225px, 0px, 0px);
  z-index: 1;
}

.animation-card1 {
  animation: card3 0.6s ease-in-out;
}

.animation-card2 {
  animation: card3 0.6s ease-in-out;
}

.animation-card3 {
  animation: card3 0.6s ease-in-out;
}

@keyframes card1 {
  0% {
    transform: scale(1.2) translate3d(-55px, 0px, 0px);
  }

  50% {
    transform: scale(1.4) translate3d(-75px, 0px, 0px);
  }

  100% {
    transform: scale(1.3) translate3d(15px, 0px, 0px);
  }
}

@keyframes card2 {
  0% {
    transform: scale(1.1) translate3d(-145px, 0px, 0px);
  }

  50% {
    transform: scale(1.3) translate3d(-165px, 0px, 0px);
  }

  100% {
    transform: scale(1.3) translate3d(15px, 0px, 0px);
  }
}

@keyframes card3 {
  0% {
    transform: scale(1) translate3d(-225px, 0px, 0px);
  }

  50% {
    transform: scale(1.2) translate3d(-245px, 0px, 0px);
  }

  100% {
    transform: scale(1.3) translate3d(15px, 0px, 0px);
  }
}